import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { wheelsService } from "../../../_services/wheels.service";
import "./geoCard.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet/dist/leaflet.css";
import { memo } from "react";
import "./geoCard.css";
import { Icon, divIcon, point } from "leaflet";
import { RotatingLines } from "react-loader-spinner";

const customIcon = new Icon({
  iconUrl: require("./icons/placeholder.png"),
  iconSize: [38, 38], // size of the icon
});
//position icon
const myPositionIcon = new Icon({
  iconUrl: require("./icons/tire-icon.png"),
  iconSize: [38, 38], // size of the icon
});

// custom cluster icon
const createClusterCustomIcon = function (cluster) {
  return new divIcon({
    html: `<span class="cluster-icon" >${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconAnchor: [16, 16],
    iconSize: point(33, 33, true),
  });
};
function GeoCard(props) {
  const { position } = props;
  const [markers, setMarkers] = useState([]);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [currentLink, setCurrentLink] = useState(null);
  const [Load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    let Filter = props.filter;
    setLoad(true);
    wheelsService.getOnlyMaps(JSON.stringify(Filter)).then((result) => {
      setData(result[0]);
      setLoad(false);
      const extractedMarkers = extractMarkers(result[0]);
    setMarkers(extractedMarkers);
    });
  }, [props.filter]);

  // markers
  const extractMarkers = (data) => {
    let markers = [];
    data.forEach((element) => {
      let point = {
        id: element.ID,
        link: "",
        geocode: [element.latitude, element.longitude],
        popup: "",
      };
      markers.push(point);
    });
    return markers;
  };
  const handleMarkerClick = async (markerID) => {
    const request = { ID: markerID };
    try {
      const result = await wheelsService.getWheelById(JSON.stringify(request));
      // Assuming the wheel data has a 'title' and 'price' property
      const popupValue = `${result[0].title}, ${result[0].price}`;
      // Assuming the wheel data has a 'url' property
      const linkValue = result[0].url;
      setCurrentPopup(popupValue);
      setCurrentLink(linkValue);
    } catch (error) {
      console.error("Error fetching wheel data:", error);
    } 
  };

  return (
    <>
      {!Load ? (
        <MapContainer
          bounds={[
            [47.2701, 5.8663],
            [55.0582, 15.0419],
          ]}
         center={position && position[0] !== 0 && position[1] !== 0 ? position : [49.0416, 9.2512]}

          maxBoundsViscosity={1.0}
          maxZoom={18}
          zoom={6}
          locale="en"
        >
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri"
          />

          {data && data.length > 0 && (
            <MarkerClusterGroup
              chunkedLoading
              iconCreateFunction={createClusterCustomIcon}
            >
              {markers.map((marker, index) => (
                <Marker
                  key={index}
                  position={marker.geocode}
                  icon={myPositionIcon}
                  eventHandlers={{
                    click: () => handleMarkerClick(marker.id),
                  }}
                >
                  <Popup>
                    <a
                      href={currentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {currentPopup}
                    </a>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          )}
          {!position.every((coord, index) => coord === [0, 0][index]) &&
            position.every((coord) => typeof coord === "number") &&
            position && (
              <Marker position={position} icon={customIcon}>
                <Popup>{props.positionName}</Popup>
              </Marker>
            )}
        </MapContainer>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <RotatingLines
            width={30}
            height={30}
            strokeColor="#9cd047"
            visible={true}
          />
        </div>
      )}
    </>
  );
}
export default memo(GeoCard);
