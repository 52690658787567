import React from "react";
import "./header.css";
import logo from "../../images/logo.png";

const Header = () => {

  return (
    <>
      <header>
        <div className="container flex">
          <div className="logo">
            <img src={logo} alt="" style={{ width: "220px", height: "60px" }} />
          </div>
      

      
        </div>
      </header>
    </>
  );
};

export default Header;
