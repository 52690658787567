export const wheelsService = {
  getFiltredWheels,
  getWheelById,
  getFiltredWheelsNoMap,
  getLengthOfData,
  getOnlyMaps,
  getAllData,
  getSarchedBytitle
};
async function getFiltredWheels(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: object,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/getFilteredWheels`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
async function getWheelById(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: id,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/getWheelByID`,
    requestOptions
  ).then(handleResponse);
}

async function getFiltredWheelsNoMap(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: object,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/getWheelOffset`,
    requestOptions
  ).then(handleResponse);
}
async function getLengthOfData(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: object,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/getLength`,
    requestOptions
  ).then(handleResponse);
}
async function getOnlyMaps(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: object,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/getMaps`,
    requestOptions
  ).then(handleResponse);
}
async function getAllData(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: object,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/getAllData`,
    requestOptions
  ).then(handleResponse);
}
async function getSarchedBytitle(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: object,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/getBytitle`,
    requestOptions
  ).then(handleResponse);
}
