import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Box from "@mui/material/Box";
import { CSVLink } from "react-csv";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisitLink from "./icons/web.png";
import { RotatingLines } from "react-loader-spinner";
import {
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import moment from "moment";
import { wheelsService } from "../../../_services/wheels.service";
import swal from "sweetalert";
import { favorisService } from "../../../_services/favoris.service";

function TableData(props) {
  const date_of_download = new Date().toISOString().slice(0, 10);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortedData, setSortedData] = useState([])
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [searchTitle, setSearchedTitle] = useState("");
  const [searchDescription, setSearchDescription] = useState("");
  const [sorting, setSorting] = useState({
    field: "length_title",
    order: "asc",
  });
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderFilter, setLoaderFilter] = useState(null);
  const [filter, setFilter] = useState(props.filter);
  const [newFolderName, setNewFolderName] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [existingFolders, setExistingFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const columns = [
    {
      title: "",
      field: "tableData.id",
      render: (rowData) => (
        <IconButton onClick={() => handleToggleFavorite(rowData)}>

          {rowData.favorite_folder !== null || selectedRows.some(row => row === rowData.ID) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton>
      ),
      width: 50,

    },
    {
      title:
        sorting.field === "ID" ? (
          sorting.order === "desc" ? (
            <span>
              ID <ArrowUpwardIcon fontSize="small" />
            </span>
          ) : (
            <span>
              ID <ArrowDownwardIcon fontSize="small" />
            </span>
          )
        ) : (
          "ID"
        ),
      field: "ID",
      width: 80,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      field: "url",
      title: "Link",
      width: 100,
      sorting: "false",
      render: (value, tableMeta) => (
        <div style={{ display: "flex", alignItems: "center" }}>


          <a onClick={() => handleRedirect(value.url)}>
            <img
              src={VisitLink}
              alt=""
              className="green-image"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </a>
        </div>
      ),
    },
    {
      field: "image",
      title: "Bild",
      with: 100,

      render: (value) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {value.image ? (
            <img
              src={value.image}
              alt=""
              style={{
                margin: "5px",
                width: "130px",
                height: "90px",
                borderRadius: "5px",
                objectFit: "cover",
              }}
            />
          ) : (
            <span style={{ fontSize: "1rem" }}>K.A</span>
          )}
        </div>
      ),
    },
    {
      title:
        sorting.field === "title" ? (
          sorting.order === "desc" ? (
            <span>
              titel <ArrowUpwardIcon fontSize="small" />
            </span>
          ) : (
            <span>
              titel <ArrowDownwardIcon fontSize="small" />
            </span>
          )
        ) : (
          "titel"
        ),
      field: "title",

      width: 150,
    },
    {
      title:
        sorting.field === "description" ? (
          sorting.order === "desc" ? (
            <span>
              description <ArrowUpwardIcon fontSize="small" />
            </span>
          ) : (
            <span>
              description <ArrowDownwardIcon fontSize="small" />
            </span>
          )
        ) : (
          "description"
        ),
      field: "description",

      width: 200,
    },
    {
      title:
        sorting.field === "adress" ? (
          sorting.order === "desc" ? (
            <span>
              Adresse <ArrowUpwardIcon fontSize="small" />
            </span>
          ) : (
            <span>
              Adresse <ArrowDownwardIcon fontSize="small" />
            </span>
          )
        ) : (
          "Adresse"
        ),
      width: 100,

      field: "adress",
    },
    {
      title:
        sorting.field === "date" ? (
          sorting.order === "desc" ? (
            <span>
              Datum <ArrowUpwardIcon fontSize="small" />
            </span>
          ) : (
            <span>
              Datum <ArrowDownwardIcon fontSize="small" />
            </span>
          )
        ) : (
          "Datum"
        ),
      field: "date",
      width: 80,

      render: (rowData) => (
        <span>{moment(rowData.date).format("DD.MM.YYYY")}</span>
      ),
    },
    {
      title:
        sorting.field === "price" ? (
          sorting.order === "desc" ? (
            <span>
              preis <ArrowUpwardIcon fontSize="small" />
            </span>
          ) : (
            <span>
              preis <ArrowDownwardIcon fontSize="small" />
            </span>
          )
        ) : (
          "preis"
        ),
      field: "price",
      width: 100
    },
    {
      title:
        sorting.field === "length_title" ? (
          sorting.order === "desc" ? (
            <span>
              Count <ArrowUpwardIcon fontSize="small" />
            </span>
          ) : (
            <span>
              Count <ArrowDownwardIcon fontSize="small" />
            </span>
          )
        ) : (
          "Count"
        ),
      field: "length_title",
      width: 100
    },
  ];

  // Function to toggle favorite status of an item
  const handleToggleFavorite = (rowData) => {

    if (rowData.favorite_folder !== null) {
      // Show confirmation dialog before removing from favorites
      swal({
        title: "Aus Favoriten Löschen?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            let object = JSON.stringify({
              selectedRows: rowData
            })
            favorisService.removeFromFavorites(object)
              .then(res => {
                swal("Erfolgreich gelöscht!", "", "success")
                // Update the favorite_folder field to null for the removed item
                const updatedSortedData = sortedData.map(item => {
                  if (item.ID === rowData.ID) {
                    return { ...item, favorite_folder: null };
                  }
                  return item;
                });
                // Update the state with the modified sortedData
                setSortedData(updatedSortedData);

              })
              .catch(error => {
                swal("Error!", "Failed to delete from favorites.", "error");
              });
          }
        });
    } else {
      const newSelectedRows = [...selectedRows];
      const selectedIndex = newSelectedRows.indexOf(rowData.ID);

      if (selectedIndex === -1) {
        newSelectedRows.push(rowData.ID);
      } else {
        newSelectedRows.splice(selectedIndex, 1);
      }

      setSelectedRows(newSelectedRows);
      setOpenDialog(true);
    }
  };


  function isFloat(value) {
    if (
      typeof value === "number" &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
    ) {
      return true;
    }

    return false;
  }
  function getDataFragment(numberPage, filteredData) {
    const lastPage = isFloat(totalRow / 200)
      ? Math.floor(totalRow / 200) + 1
      : Math.floor(totalRow / 200);

    setPageNumber(numberPage);
    setTotalRow(filteredData.length);

  }
  const handleRedirect = (url) => {
    window.open(url, "_blank");

  };
  //get all data
  const getAllData = () => {
    setLoaderFilter(true)
    wheelsService.getAllData(JSON.stringify(filter)).then((result) => {
      setLoaderFilter(true)
      if (result[0].length > 0) {
        setAllData(result[0]);
        setLoaderFilter(false)
      }
    });
  };
  //set the sorting data 
  const handleSortingChange = (field, order) => {
    const fieldName = columns[field].field;
    const newOrder =
      sorting.field === fieldName && sorting.order === "asc" ? "desc" : "asc";
    setSorting({ field: fieldName, order: newOrder });
  };
  //function to fetch and sort data
  async function fetchData(filter, sorting, e) {
    try {
      setLoader(true);
      const result = await wheelsService.getFiltredWheelsNoMap(JSON.stringify({ ...filter, offset: e, sortingField: sorting.field, sortingOrder: sorting.order }));
      const sortedItems = result.slice().sort((a, b) => {
        if (sorting.order === "asc") {
          return a[sorting.field] > b[sorting.field] ? 1 : -1;
        } else {
          return a[sorting.field] < b[sorting.field] ? 1 : -1;
        }
      });
      setSortedData(sortedItems)
      setLoader(false);

      getDataFragment(e, result);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);

    }


  }
  //filtred by title while paginated
  function getDataByTitle(filter, e, keyword, description, sorting) {
    if (keyword !== "" || description !== "") {
      setLoader(true)

      wheelsService
        .getSarchedBytitle(
          JSON.stringify({ ...filter, offset: e, searchtitle: keyword, searchDescription: description })
        )
        .then((result) => {
          setLoader(false)
          const sortedItems = result[1].slice().sort((a, b) => {
            if (sorting.order === "asc") {
              return a[sorting.field] > b[sorting.field] ? 1 : -1;
            } else {
              return a[sorting.field] < b[sorting.field] ? 1 : -1;
            }
          });
          setSortedData(sortedItems)
          setDataLength(result[0]);
          getDataFragment(e, result[1])
        });
    }
    else {
      swal({
        title: "HINWEIS",
        text: "Bitte einen Suchwert eingeben",
        button: "OK",
      });
    }
  }
  //pagination
  function handleChangePage(e) {
    // props.updateState(e);
    setLoader(true)
    if (searchTitle !== "" || searchDescription !== "") {
      getDataByTitle(filter, e, searchTitle, searchDescription, sorting)
    }
    else {
      fetchData(filter, sorting, e)
    }
  }


  //filtred data with search text in autocomplete
  const handleChangeFilter = (e, type) => {
    let searchString = e.target.value;
    if (type == "title") { setSearchedTitle(searchString); }
    else if (type == "desc") { setSearchDescription(searchString) }
    // Check if the input is empty, and if so, call fetchData
    if (searchString === "") {
      if (type == "desc" && searchTitle !== "") {
        setSearchDescription(searchString)
        getDataByTitle(filter, 1, searchTitle, searchString, sorting)
      }
      else if (type == "title" && searchDescription !== "") {
        setSearchedTitle(searchString)
        getDataByTitle(filter, 1, searchString, searchDescription, sorting)
      } else {
        fetchData(filter, sorting, filter.offset);
        setDataLength(props.dataLength)
      }
    }
  };
  useEffect(() => {
    if (props.filter) {
      setLoaderFilter(null)
      setFilter(props.filter);

      if (searchTitle !== "" || searchDescription !== "") {
        getDataByTitle(props.filter, props.filter.offset, searchTitle, searchDescription, sorting)
      }
      else
        fetchData(props.filter, sorting, props.filter.offset)
    }

  }, [props.filter, sorting]);


  useEffect(() => {
    if (props.dataLength) {
      setDataLength(props.dataLength)
    }

  }, [props.dataLength]);
  // Fetch unique folder names
  const fetchUniqueFolderNames = () => {
    favorisService.getUniqueFolderName().then((res) => {
      setExistingFolders(res);
    });
  };


  useEffect(() => {
    fetchUniqueFolderNames();

  }, []);

  // Handle adding to favorites after selecting or entering folder name
  const handleAddToFavoritesConfirm = async () => {
    if (newFolderName || selectedFolder) {
      let object = JSON.stringify({
        folderName: newFolderName || selectedFolder, // Use selectedFolder if newFolderName is empty
        selectedRows: selectedRows,
      });
      favorisService.addFavorisProduct(object).then((res) => {
        if (res && res.success) {
          swal("Erfolgreich Hinzugefügt!", "", "success");
          setSelectedRows([]);

          // Update the favorite_folder field to newFolderName for the newly added item
          const updatedSortedData = sortedData.map(item => {
            if (selectedRows.some(row => row === item.ID)) {
              return { ...item, favorite_folder: newFolderName };
            }
            return item;
          });

          // Update the state with the modified sortedData
          setSortedData(updatedSortedData);
        }

      });
      fetchUniqueFolderNames()
      setOpenDialog(false);
      setNewFolderName("");
      setSelectedFolder(null); // Reset selectedFolder after adding to favorites
    } else {
      swal("Error!", "Please enter a folder name or select a folder.", "error");
    }
  };
  const handlereset = async () => {
    setSelectedRows([]);
    setOpenDialog(false)
    setNewFolderName("")
    setSelectedFolder(null);
  }
  return (
    <div className="container">
      <Box px={{ xs: "12px", lg: 0 }} className="page-space-top">
        <div
          className="dataTable"
        >

          <Box sx={{ height: "100%", width: "100%" }}>
            {props.Loader || loader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  width={60}
                  height={60}
                  strokeColor="#9cd047"
                  visible={true}
                />
              </div>
            ) : (
              <>


                <div
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%"
                    }}
                  >


                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div style={{
                        display: "flex"
                      }} >
                        <TextField
                          InputLabelProps={{
                            shrink: false,
                            style: {
                              textTransform: "uppercase",
                              padding: "0 5px 0 0",
                              backgroundColor: "white",
                            },
                          }}
                          id="searchTitle"
                          placeholder="Titel durchsuchen"
                          variant="outlined"
                          style={{
                            marginLeft: "0px",
                            marginRight: "0px",
                            marginBottom: "10px",
                            marginTop: "0.21rem",
                          }}
                          value={searchTitle}
                          onChange={(event) => {
                            handleChangeFilter(event, 'title');
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon sx={{ color: "#9cd047" }} />
                              </InputAdornment>
                            ),

                          }}
                        />
                        <button
                          className="btn btn-primary green-button"
                          style={{
                            marginLeft: "auto",
                            marginBottom: "10px",
                            marginRight: "2px",
                            color: "#fff",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px",
                            backgroundColor: "#9cd047",
                            border: "1px solid #9cd047",
                            "&:hover": {
                              color: "#9cd047",
                              backgroundColor: "#fff",
                              border: "1px solid #9cd047",
                              variant: "outlined",
                            },
                          }}
                          onClick={() => getDataByTitle(filter, 1, searchTitle, searchDescription, sorting)}
                        >
                          SUCHE
                        </button>
                      </div>
                      <div style={{
                        display: "flex"
                      }} >
                        <TextField
                          InputLabelProps={{
                            shrink: false,
                            style: {
                              textTransform: "uppercase",
                              padding: "0 5px 0 0",
                              backgroundColor: "white",
                            },
                          }}
                          id="searchDescription"
                          placeholder="Beschreibung durchsuchen"
                          variant="outlined"
                          style={{
                            marginLeft: "0px",
                            marginRight: "0px",
                            marginBottom: "10px",
                            marginTop: "0.21rem",
                          }}
                          value={searchDescription}
                          onChange={(event) => {
                            handleChangeFilter(event, 'desc');
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon sx={{ color: "#9cd047" }} />
                              </InputAdornment>
                            ),

                          }}
                        />
                        <button
                          className="btn btn-primary green-button"
                          style={{
                            marginLeft: "auto",
                            marginBottom: "10px",
                            marginRight: "2px",
                            color: "#fff",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px",
                            backgroundColor: "#9cd047",
                            border: "1px solid #9cd047",
                            "&:hover": {
                              color: "#9cd047",
                              backgroundColor: "#fff",
                              border: "1px solid #9cd047",
                              variant: "outlined",
                            },
                          }}
                          onClick={() => getDataByTitle(filter, 1, searchTitle, searchDescription, sorting)}
                        >
                          SUCHE
                        </button>
                        <div style={{ display: "flex" }}>

                          <div style={{
                            display: "flex"
                          }}>
                            {loaderFilter == null || loaderFilter == true ?
                              <button
                                className={loaderFilter ? "btn btn-primary disabled-button" : "btn button-color-black"}
                                style={{
                                  marginLeft: "auto",
                                  marginBottom: "10px",
                                  marginRight: "2px",

                                }}
                                onClick={() => getAllData()}
                              >
                                {loaderFilter ? <a>CSV wir geladen...</a>
                                  :
                                  <span>CSV Download</span>
                                }
                              </button>
                              : loaderFilter == false ?

                                <CSVLink
                                  className="btn btn-primary"
                                  style={{
                                    marginLeft: "auto",
                                    marginBottom: "10px",
                                    marginRight: "2px",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#fff",
                                    backgroundColor: "#9cd047",
                                    border: "1px solid #9cd047",
                                    "&:hover": {
                                      color: "#9cd047",
                                      backgroundColor: "#fff",
                                      border: "1px solid #9cd047",
                                      variant: "outlined",
                                    },
                                  }}
                                  separator=";"
                                  data={allData}
                                  filename={`bekstein_annoncen_${date_of_download}.csv`}
                                >
                                  Jetzt herunterladen
                                </CSVLink>
                                : <></>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ height: "100%", width: "100%", overflow: "auto" }}
                    className="ag-fresh customer-table"
                  >
                    <MaterialTable
                      localization={{
                        body: {
                          emptyDataSourceMessage:
                            sortedData.length == 0 &&
                            <div style={{ padding: "20px 0px" }}>
                              Leider wurden keine übereinstimmenden Datensätze gefunden.

                            </div>
                        },

                        pagination: {
                          labelDisplayedRows: "{from} bis {to} von {count} ",
                          labelRowsSelect: "Zeilen",
                          previousAriaLabel: "Vorherige Seite",
                          previousTooltip: "Vorherige Seite",
                          nextAriaLabel: "Nächste Seite",
                          nextTooltip: "Nächste Seite",
                          lastAriaLabel: "Letzte Seite",
                          lastTooltip: "Letzte Seite",
                        },
                      }}
                      title={""}
                      data={sortedData}
                      columns={columns}
                      options={{
                        toolbar: false,
                        loading: true,
                        sorting: true,
                        pageSize: 200,
                        pageSizeOptions: false,
                        actionsColumnIndex: -1,
                        search: false,
                      }}
                      components={{
                        Pagination: (props) => (
                          <TablePagination
                            {...props}
                            /* rowsPerPageOptions={[5]} */
                            rowsPerPage={200}
                            // count={totalRow}
                            count={dataLength}
                            page={pageNumber - 1}
                            onChangePage={(e, page) => {
                              handleChangePage(page + 1);
                            }}
                          />
                        ),
                      }}
                      onOrderChange={(orderedColumnId, orderDirection) => {
                        handleSortingChange(orderedColumnId, orderDirection);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </Box>
        </div>
      </Box>
      {/* Dialog for selecting folder or entering new folder name */}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setSelectedRows([]);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Zu Favoriten hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Suchen einen Ordner aus der Liste aus oder geben den Namen eines neuen Ordner in das Textfeld ein.
          </DialogContentText>
          <Box position="relative">
            <TextField
              margin="dense"
              id="newFolderName"
              label="Ordner erstellen"
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              fullWidth
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              InputProps={{ style: { zIndex: !isFocused && !newFolderName ? 0 : 2 } }}
            />
            {!isFocused && !newFolderName && (
              <Box
                position="absolute"
                left={11}
                top={17}
                color="gray"
                fontStyle="italic"
                zIndex={1}
              >
                Neuen Ordnernamen eingeben
              </Box>
            )}
          </Box>

          <div style={{ marginTop: "10px" }}>
            {existingFolders.map((folder, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center", padding: '5px 0px' }}>
                <input
                  type="radio"
                  id={`folder_${index}`}
                  name="folder"
                  value={folder}
                  checked={selectedFolder === folder}
                  onChange={() => setSelectedFolder(folder)}
                />
                <label htmlFor={`folder_${index}`} style={{ marginLeft: "5px", marginBottom: '0px' }}>{folder}</label>
              </div>
            ))}
          </div>

        </DialogContent>
        <DialogActions>
          <button onClick={handlereset} style={{ backgroundColor: 'red' }}>
            Abbrechen
          </button>
          <button onClick={handleAddToFavoritesConfirm} >
            Hinzufügen
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default (TableData);
