export const favorisService = {
    addFavorisProduct,
    getUniqueFolderName,
    checkFolderExists,
    getFavoritesByFolders,
    getAllFavoritesByFolders,
    getCoordinateFavorite,
    getMarkerDetailsById,
    removeFromFavorites,
    allProducts,
    removeFolderFavorites
  };
  async function addFavorisProduct(object) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: object,
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/add-favorites`,
      requestOptions
    ).then(handleResponse);
  }
  async function allProducts() {
    const requestOptions = {
      method: "GET",
     
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/all`,
      requestOptions
    ).then(handleResponse);
  }
  async function removeFolderFavorites(object) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: object,
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/removeFolder`,
      requestOptions
    ).then(handleResponse);
  }
  async function removeFromFavorites(object) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: object,
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/remove`,
      requestOptions
    ).then(handleResponse);
  }
  async function checkFolderExists(object) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: object,
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/exist`,
      requestOptions
    ).then(handleResponse);
  }
  
  async function getUniqueFolderName() {
    const requestOptions = {
      method: "GET",
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/favorite_folders`,
      requestOptions
    ).then(handleResponse);
  }
  async function getFavoritesByFolders(object) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: object,
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/getFavoritesByFolders`,
      requestOptions
    ).then(handleResponse);
  }
  async function getAllFavoritesByFolders(object) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: object,
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/getAllFavoritesByFolders`,
      requestOptions
    ).then(handleResponse);
  }
  async function getCoordinateFavorite(object) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: object,
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/getCoordinate`,
      requestOptions
    ).then(handleResponse);
  }
  async function getMarkerDetailsById(object) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: object,
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/favoris/getMarkerById`,
      requestOptions
    ).then(handleResponse);
  }
  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }
  
  