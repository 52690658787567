import React from "react";
import Featured from "./featured/Featured";
import "./hero/hero.css";

const Home = () => {
  return (
    <>
      <Featured />
    </>
  );
};

export default Home;
