import React, { useEffect, useState } from "react";
import Heading from "../../common/Heading";
import "./Featured.css";
import TableData from "./TableData";
import { RotatingLines } from "react-loader-spinner";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import geoData from "./data/germany_plz_cities.json";
import swal from "sweetalert";
import {
  Autocomplete, InputAdornment,
  Stack, TextField, Dialog, DialogTitle, IconButton,List, ListItem, ListItemText, Checkbox, FormControlLabel, Button
} from "@mui/material";
import GeoCard from "./GeoCard";
import { wheelsService } from "../../../_services/wheels.service";
import { EuroSymbol } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { favorisService } from "../../../_services/favoris.service";
import FavorisTable from "./favorisData";
import FavorisCard from "./favorisCard";
import DeleteIcon from '@mui/icons-material/Delete';

const initialPosition = [49.0416, 9.2512];
const months = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

const Featured = () => {
  const [dataLengh, setDataLengh] = useState(0);
  const [positionName, setPositionName] = useState("");
  const [activeButton, setActiveButton] = useState("mapon");
  const [thePositionName, setThepositionName] = useState("Ilsfeld, 74360 ");
  const [postalCode, setPostalCode] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState("");
  var nowDate = new Date();
  const handleChange = (type) => {
    if (type == "favoris") {
      setLoad(true);
      fetchUniqueFolderNames();
    }
    setSelectedFolders([])
    setActiveButton(type);
    setFilter({ ...Filter, preisRange1: null });
    Reset();
  };
  const handleSelectOption = (e, newValue) => {
    if (newValue) {
      setSelectedOption(newValue);
      const thePosition = [
        parseFloat(newValue?.latitude),
        parseFloat(newValue?.longitude),
      ];
      setPosition(thePosition);

      setPositionName(newValue?.city + " ," + newValue?.zipcode);
      setSearchText(newValue?.city + " ," + newValue?.zipcode);
      setFilter({
        ...Filter,
        position: thePosition,
        postalCode: newValue?.zipcode,
      });
      setPostalCode(newValue?.zipcode);
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [folders, setFolders] = useState([]);
  const [Load, setLoad] = useState(false);
  const [thePosition, setTheposition] = useState(initialPosition);
  const [position, setPosition] = useState(initialPosition);
  const [offset, setOffset] = useState(1);
  const [radius, setRadius] = useState(null);
  const [Filter, setFilter] = useState({
    position: initialPosition,
    postalCode: null,
    radius: [null],
    dateRange1: nowDate.toISOString().slice(0, 10),
    dateRange2: nowDate.toISOString().slice(0, 10),
    countRange1: null,
    countRange2: null,
    preisRange1: null,
    preisRange2: null,
  });
  const [filterToSend, setFilterTosend] = useState({
    postalCode: null,

    position: initialPosition,
    radius: [null],
    dateRange: [
      nowDate.toISOString().slice(0, 10),
      nowDate.toISOString().slice(0, 10),
    ],
    countRange: [null, null],
    preisRange: [null, null],
    offset: 1,
  });
  const [selectedFolders, setSelectedFolders] = React.useState([]);



  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = "hidden"; // Prevent scrolling when the overlay is active
    } else {
      document.body.style.overflow = ""; // Restore scrolling when the overlay is closed
    }
  }, [showPopup]);
  // Function to fetch unique folder names
  const fetchUniqueFolderNames = () => {
    favorisService.getUniqueFolderName().then((res) => {
      setFolders(res);
      setShowPopup(true); // Show popup after fetching folders
    });
  };

useEffect(() => {
  if (showPopup) {
    document.body.style.overflow = "hidden"; // Prevent scrolling when the overlay is active
  } else {
    document.body.style.overflow = ""; // Restore scrolling when the overlay is closed
  }
}, [showPopup]);


  const handleOffset = (e) => {
    setOffset(e);
  };
  let now = new Date();
  let startDatePiker = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );
  let endDatePiker = moment(startDatePiker)
    .add(1, "days")
    .subtract(1, "seconds");
  const [start, setStart] = useState(moment());
  const [end, setEnd] = useState(moment());
  const [value, setValue] = useState(
    `${startDatePiker.format("DD-MM-YYYY")} - ${endDatePiker.format(
      "DD-MM-YYYY"
    )}`
  );
  function applyCallback(startDate, endDate) {
    let start = moment(startDate);
    let end = moment(endDate);
    if (end.diff(start, "days") > 31) {
      swal({
        title: "HINWEIS",
        text: "Bitte wählen Sie als Datumsbereich maximal 30 Tage aus!",
        button: "OK",
      });
    } else {
      setStart(startDate);
      setEnd(endDate);
      startDate.format("DD-MM-YYYY");
      endDate.format("DD-MM-YYYY");

      setFilter((prevFilter) => ({
        ...prevFilter,
        dateRange1: startDate.format("YYYY-MM-DD").slice(0, 10),
        dateRange2: endDate.format("YYYY-MM-DD").slice(0, 10),
      }));
      const formattedValue = `${startDate.format(
        "DD-MM-YYYY"
      )} - ${endDate.format("DD-MM-YYYY")}`;
      setValue(formattedValue);
    }
  }
  const handleRadiusChange = (newRadius) => {

    setRadius(newRadius);
    setFilter({ ...Filter, radius: [newRadius] });
  };
  const Reset = () => {
    setSearchText("");
    setLoad(false);
    setPosition([49.0416, 9.2512]);
    setTheposition([49.0416, 9.2512]);
    setThepositionName("Ilsfeld, 74360 ");
    setOffset(1);
    setLoad(true);
    let newFilter = {
      position: [49.0416, 9.2512],
      postalCode: null,
      radius: [null],
      dateRange1: nowDate.toISOString().slice(0, 10),
      dateRange2: nowDate.toISOString().slice(0, 10),
      countRange1: null,
      countRange2: null,
      preisRange1: null,
      preisRange2: null,
      offset: 1,
    };
    setFilter(newFilter);
    applyCallback(moment(), moment());
    let resetFilter = {
      postalCode: null,

      position: newFilter.position,
      radius: newFilter.radius,
      dateRange: [newFilter.dateRange1, newFilter.dateRange2],
      countRange: [newFilter.countRange1, newFilter.countRange2],
      preisRange: [newFilter.preisRange1, newFilter.preisRange2],
      offset: 1,
    };
    setFilterTosend(resetFilter);
    setValue(
      `${startDatePiker.format("DD-MM-YYYY")} - ${endDatePiker.format(
        "DD-MM-YYYY"
      )}`
    );
    setPositionName("");
    setSelectedOption(null);
    wheelsService
      .getLengthOfData(JSON.stringify(resetFilter))
      .then((result) => {
        setLoad(false);
        // if (result.length > 0)
        setDataLengh(result);
        setLoad(false);
      });
  };
  const handleReset = (e) => {
    e.preventDefault();
    setSearchText("");
    setLoad(false);
    setPosition([49.0416, 9.2512]);
    setTheposition([49.0416, 9.2512]);
    setThepositionName("Ilsfeld, 74360 ");
    setOffset(1);
    setLoad(true);
    setStart(moment());
    setEnd(moment());
    let newFilter = {
      position: [49.0416, 9.2512],
      postalCode: null,
      radius: [null],
      dateRange1: nowDate.toISOString().slice(0, 10),
      dateRange2: nowDate.toISOString().slice(0, 10),
      countRange1: null,
      countRange2: null,
      preisRange1: null,
      preisRange2: null,
      offset: 1,
    };

    setFilter(newFilter);
    applyCallback(moment(), moment());
    let resetFilter = {
      postalCode: null,

      position: newFilter.position,
      radius: newFilter.radius,
      dateRange: [newFilter.dateRange1, newFilter.dateRange2],
      countRange: [newFilter.countRange1, newFilter.countRange2],
      preisRange: [newFilter.preisRange1, newFilter.preisRange2],
      offset: 1,
    };
    setFilterTosend(resetFilter);
    setValue(
      `${startDatePiker.format("DD-MM-YYYY")} - ${endDatePiker.format(
        "DD-MM-YYYY"
      )}`
    );
    setPositionName("");
    setSelectedOption(null);
    wheelsService
      .getLengthOfData(JSON.stringify(resetFilter))
      .then((result) => {
        setLoad(false);
        // if (result.length > 0)

        setDataLengh(result);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (activeButton == "mapon" && selectedOption === null) {
      swal({
        title: "HINWEIS",
        text: "Sie müssen eine PLZ oder einen Ort eingeben um zu filtern!",
        button: "OK",
      });
    } else if (activeButton == "mapon" && parseFloat(Filter.radius[0]) > 100) {
      swal({
        title: "HINWEIS",
        text: "Der Radius darf einen Maximalwert von 100 haben",
        button: "OK",
      });
    }
    else if (!activeButton == "mapon" && Filter.radius[0] !== null && selectedOption == null) {
      swal({
        title: "HINWEIS",
        text: "Bitte eine PLZ oder Ort eingeben",
        button: "OK",
      });
    }
    else {
      setLoad(true);
      const newFilter = {
        postalCode: Filter.postalCode,
        position: Filter.position,
        radius: Filter.radius,
        dateRange: [Filter.dateRange1, Filter.dateRange2],
        countRange: [Filter.countRange1, Filter.countRange2],
        preisRange: [Filter.preisRange1, Filter.preisRange2],
        offset: 1,
      };
      setFilterTosend(newFilter);
      wheelsService
        .getLengthOfData(JSON.stringify(newFilter))
        .then((result) => {
          setLoad(false);
          setDataLengh(result);

        });

      setTheposition(position);
      setThepositionName(positionName);
    }
  };
  useEffect(() => {
    const newFilter = {
      postalCode: postalCode,
      position: Filter.position,
      radius: Filter.radius,
      dateRange: [Filter.dateRange1, Filter.dateRange2],
      countRange: [Filter.countRange1, Filter.countRange2],
      preisRange: [Filter.preisRange1, Filter.preisRange2],
      offset: 1,
    };
    setLoad(true)
    wheelsService.getLengthOfData(JSON.stringify(newFilter)).then((result) => {
      setLoad(false);
      setDataLengh(result);
    });
  }, []);
  let ranges = {
    Heute: [moment(startDatePiker), moment(endDatePiker)],
    Gestern: [
      moment(startDatePiker).subtract(1, "days"),
      moment(endDatePiker).subtract(1, "days"),
    ],
    "Letzte Woche": [
      moment(startDatePiker).subtract(7, "days"),
      moment(endDatePiker),
    ],
    "Letzter Monat": [
      moment(startDatePiker).subtract(1, "months"),
      moment(endDatePiker),
    ],
    "Custom Range": null,
  };
  let local = {
    format: "DD-MM-YYYY",
    sundayFirst: false,
    months: months,
    days: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    selectingFrom: "Startdatum",
    close: "Close",
    apply: "Anwenden",
    cancel: "Abberechen",
    fromDate: "Startdatum",
    toDate: "Enddatum",
    customRange: "Benutzerdefiniert",
  };

  const handleConfirm = () => {
    setLoad(false);
    setShowPopup(false)

  };
  const handleToggle = (folder) => () => {
    // Update selectedFolders immutably
    const updatedSelectedFolders = [...selectedFolders];
    const currentIndex = updatedSelectedFolders.indexOf(folder);
    if (currentIndex === -1) {
      updatedSelectedFolders.push(folder);
    } else {
      updatedSelectedFolders.splice(currentIndex, 1);
    }
    setSelectedFolders(updatedSelectedFolders);
  };
  const handleSelectAll = () => {
    if (selectedFolders.length === folders.length) {
      setSelectedFolders([]);
    } else {
      setSelectedFolders(folders);
    }
  };
  const handleCloseDialog = () => {
    if (selectedFolders.length === 0) {
      // If no selections have been made, select all folders
      setSelectedFolders(folders);
    }
    setShowPopup(false);
  };
// Implement the handleDelete function
const handleDelete = (folder) => {
  swal({
    title: "Favoriten Löschen?",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // Handle delete action here
      let object=JSON.stringify({
        folderName:folder
      })
      favorisService.removeFolderFavorites(object)
        .then(res => {
          // Show success message
          swal("Erfolgreich gelöscht!", "", "success");
          setSelectedFolders([])
          fetchUniqueFolderNames()
        })
        .catch(error => {
          // Show error message
          swal("Error!", "Failed to delete from favorites.", "error");
          // Handle any error scenarios
        });
    }
  });
};
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="row content-height">
            <div className="col-md-12 mt-3" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <button
                style={{ margin: "0px 5px" }}
                className={`${activeButton == "mapon" ? "active" : "inactive"} `}
                onClick={() => handleChange("mapon")}
              >MAP ON</button>
              <button
                style={{ margin: "0px 5px" }}
                className={`${activeButton == "mapoff" ? "active" : "inactive"} `}
                onClick={() => handleChange("mapoff")}
              >MAP OFF</button>

              <button
                style={{ margin: "0px 5px" }}
                className={`${activeButton == "favoris" ? "active" : "inactive"} `}
                onClick={() => handleChange("favoris")}
              >FAVORITEN</button>
            </div>
            {activeButton !== "favoris" &&
              <div className={activeButton == "mapon" ? "col-md-6" : "col-md-12"}>
                <div className="featured background form-filter">
                  <Heading
                    title="BEKSTEIN ANNONCEN"
                    subtitle="Gib einfach eine PLZ oder einen Ort ein und klicke auf Filtern. Mit dem Slide kannst Du den Radius bestimmen."
                  />
                  <form
                    style={{
                      backgroundColor: "transparent",
                      marginTop: "0px",
                    }}
                    className={activeButton == "mapon" ? "pdg-form" : "padding-form"}
                  >

                    <div className="row">
                      <div className="col-md-8">
                        <Stack sx={{ width: "100%" }}>
                          <Autocomplete
                            options={geoData}
                            disablePortal={true}
                            id="combo-box-demo"
                            getOptionLabel={(option) =>
                              option.zipcode + ", " + option.city
                            }
                            sx={{
                              display: "flex",

                              alignItems: "center",
                              width: "100%",
                              "& .MuiInputBase-root": {
                                flex: 1,
                              },
                              "& .MuiIconButton-root": {
                                padding: "0px",
                              },
                              "& .MuiAutocomplete-clearIndicator": {
                                marginBottom: "0",
                              },
                              "& .MuiAutocomplete-popupIndicator": {},
                            }}
                            value={selectedOption}
                            onChange={handleSelectOption}
                            onInputChange={(e, value) => {
                              setSearchText(value);
                            }}
                            onBlur={() => {
                              if (!searchText) {
                                setSelectedOption(null);
                                setPosition([0, 0]);
                                setTheposition([0, 0]);
                                setFilter({
                                  ...Filter,
                                  radius: [null],
                                  position: [0, 0],
                                  postalCode: null,
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Ort / PLZ"
                                style={{ width: "30rem", flex: 1 }}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                      <div className="col-md-4">
                        <TextField
                          name="slider"
                          type="number"
                          value={Filter.radius || null}
                          max={100}
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value;

                            handleRadiusChange(newValue);

                          }}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Km</InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mt-3 mb-3" >
                      <div className="col-md-4 flex-direction-column ">
                        <span className="title-input">Preis :</span>
                        <TextField
                          name="preis1"
                          type="number"
                          value={
                            Filter.preisRange1 !== null ? Filter.preisRange1 : ""
                          }
                          placeholder="Von"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EuroSymbol sx={{ width: "20px" }} />
                              </InputAdornment>
                            ),
                          }}
                          style={{ backgroundColor: "white" }}
                          onChange={(e) =>
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              preisRange1:
                                e.target.value === ""
                                  ? null
                                  : parseFloat(e.target.value),
                            }))
                          }
                        />
                        <TextField
                          name="preis2"
                          type="number"
                          placeholder="Bis"
                          value={
                            Filter.preisRange2 !== null ? Filter.preisRange2 : ""
                          }
                          style={{
                            backgroundColor: "white",
                            marginTop: "5px",
                          }}
                          onChange={(e) =>
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              preisRange2:
                                e.target.value === ""
                                  ? null
                                  : parseFloat(e.target.value),
                            }))
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EuroSymbol sx={{ width: "20px" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="col-md-4 flex-direction-column ">
                        <span className="title-input">ANZAHL WÖRTER :</span>
                        <TextField
                          name="count1"
                          type="number"
                          placeholder="Von"
                          value={
                            Filter.countRange1 !== null ? Filter.countRange1 : ""
                          }
                          style={{
                            backgroundColor: "white",
                          }}
                          onChange={(e) =>
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              countRange1:
                                e.target.value === ""
                                  ? null
                                  : parseFloat(e.target.value),
                            }))
                          }
                        />
                        <TextField
                          name="count2"
                          type="number"
                          value={
                            Filter.countRange2 !== null ? Filter.countRange2 : ""
                          }
                          placeholder="Bis"
                          style={{
                            backgroundColor: "white",
                            marginTop: "5px",
                          }}
                          onChange={(e) =>
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              countRange2:
                                e.target.value === ""
                                  ? null
                                  : parseFloat(e.target.value),
                            }))
                          }
                        />
                      </div>
                      <div className="col-md-4 flex-direction-column ">
                        <span className="title-input">Datum Filter :</span>
                        <DateTimeRangeContainer
                          ranges={ranges}
                          start={start}
                          end={end}
                          maxDate={moment(
                            new Date(
                              now.getFullYear(),
                              now.getMonth(),
                              now.getDate(),
                              0,
                              0,
                              0,
                              0
                            )
                          )
                            .add(1, "days")
                            .subtract(1, "seconds")}
                          local={local}
                          applyCallback={applyCallback}
                          smartMode
                          disableCustomRanges={true}
                        >
                          <TextField
                            id="formControlsTextB"
                            type="text"
                            label="Datumsbereich"
                            placeholder="Datumsbereich auswählen"
                            style={{
                              cursor: "pointer",
                              // width: "13.15rem",
                              width: "100%",
                            }}
                            value={value}
                          />
                        </DateTimeRangeContainer>
                      </div>
                    </div>

                    <div
                      className="row mt-4 "
                      style={{ caretColor: "transparent" }}
                    >
                      <div className="col-md-4 d-flex flex-row align-items-center justify-content-between ">
                        <button
                          style={{ backgroundColor: "#bd3a3a" }}
                          className={activeButton == "mapoff" ? "btn1 button-search w-full" : "btn1 button-search "}
                          onClick={handleReset}
                        >
                          zurücksetzen
                        </button>
                      </div>
                      <div className="col-md-4 d-flex flex-row align-items-center justify-content-between ">
                        <button
                          className={activeButton == "mapoff" ? "btn1 button-search w-full " : "btn1 button-search "}
                          onClick={(e) => handleSubmit(e)}>
                          Filtern
                        </button>
                      </div>
                      <div className=" col-md-4 d-flex flex-row   align-items-center justify-content-center">

                        <div
                          className="mt-2 mb-3"
                          style={{ marginLeft: "2.5rem" }}
                        >
                          <span
                            style={{
                              color: "#060817",
                              fontWeight: "600",
                              fontSize: "1.2rem",
                            }}
                          >
                            Ergebnis:
                            {Load ? (
                              <RotatingLines
                                width={30}
                                height={30}
                                strokeColor="#9cd047"
                                visible={true}
                              />
                            ) : (
                              <span
                                style={{
                                  color: "#9cd047",
                                  fontWeight: "600",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {" " + dataLengh}
                              </span>
                            )}
                          </span>
                        </div>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
            {activeButton == "mapon" ?
              <div className="col-md-6">
                <div className="hero">
                  <GeoCard
                    searchText={searchText}
                    radius={radius}
                    positionName={thePositionName}
                    position={thePosition}
                    filter={filterToSend}
                  />
                </div>
              </div>

              : activeButton == "favoris" ?
                <div className="col-md-12">
                  <div className="hero hero-height">
                    <FavorisCard
                      folders={selectedFolders}
                    />
                  </div>
                </div>
                :
                <></>

            }
          </div>

          <div className="row padding-table">
            <div className="col-md-12" style={{ caretColor: "transparent" }}>
              {activeButton == "favoris" ?
                <FavorisTable
                  updateState={handleOffset}
                  Loader={Load}
                  state={offset}
                  activeButton={activeButton}
                  favorisFolders={selectedFolders}
                />
                : <TableData
                  dataLength={dataLengh}
                  filter={filterToSend}
                  updateState={handleOffset}
                  Loader={Load}
                  state={offset}


                />
              }
            </div>
          </div>
        </div>


      </div>
      {showPopup && <div className="overlay" onClick={handleCloseDialog} />}
      <Dialog open={showPopup} onClose={() => handleCloseDialog()} maxWidth="sm" fullWidth>
        <DialogTitle>Wähle einen Ordner
          <IconButton
            style={{ position: 'absolute', top: '10px', right: '10px' }}
            onClick={() => handleCloseDialog()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <List style={{ padding: "25px" }}>
          <ListItem dense button onClick={handleSelectAll} style={{ width: "100%", padding: "5px 0px", marginLeft: "20px" }}>
            <Checkbox
              checked={selectedFolders.length === folders.length}
              indeterminate={selectedFolders.length > 0 && selectedFolders.length < folders.length}
              color="primary"
              inputProps={{ 'aria-label': 'select all folders' }}
            />
            <ListItemText primary="Alle auswählen" />

          </ListItem>
          {folders.map((folder) => (
            <ListItem key={folder} dense button onClick={handleToggle(folder)} style={{ width: "100%", padding: "5px 0px", marginLeft: "10px" }}>
              <FormControlLabel style={{ width: "100%", marginLeft: "10px",marginBottom:"0px" }}
                control={<Checkbox checked={selectedFolders.indexOf(folder) !== -1} />}
                label={folder}
              />
               <IconButton edge="end" aria-label="delete" style={{color:'red'}} onClick={() => handleDelete(folder)}>
      <DeleteIcon />
    </IconButton>
            </ListItem>
          ))}
        </List>
        <div style={{ justifyContent: "center", display: "flex" }} className="mb-3">
          <button onClick={handleConfirm} >
          Auswahl bestätigen
          </button>
        </div>
      </Dialog>
    </>
  );

};

export default Featured;
