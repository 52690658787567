import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { wheelsService } from "../../../_services/wheels.service";
import "./geoCard.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet/dist/leaflet.css";
import { memo } from "react";
import "./geoCard.css";
import { Icon, divIcon, point } from "leaflet";
import { RotatingLines } from "react-loader-spinner";
import { favorisService } from "../../../_services/favoris.service";

const customIcon = new Icon({
    iconUrl: require("./icons/placeholder.png"),
    iconSize: [38, 38], // size of the icon
});
//position icon
const myPositionIcon = new Icon({
    iconUrl: require("./icons/tire-icon.png"),
    iconSize: [38, 38], // size of the icon
});

// custom cluster icon
const createClusterCustomIcon = function (cluster) {
    return new divIcon({
        html: `<span class="cluster-icon" >${cluster.getChildCount()}</span>`,
        className: "custom-marker-cluster",
        iconAnchor: [16, 16],
        iconSize: point(33, 33, true),
    });
};
function FavorisCard(props) {
    const [markers, setMarkers] = useState([]);
    const [currentPopup, setCurrentPopup] = useState(null);
    const [currentLink, setCurrentLink] = useState(null);
    const [Load, setLoad] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
        console.log(props.folders.length)
        if (props.folders && props.folders.length > 0) {
            setLoad(true);
            let object = JSON.stringify({ folderNames: props.folders })
            favorisService.getCoordinateFavorite(object).then((result) => {
                setData(result);
                setLoad(false);
                const extractedMarkers = extractMarkers(result);
                setMarkers(extractedMarkers);
            });
        }
    }, [props.folders]);

    // markers
    const extractMarkers = (data) => {
        let markers = [];
        data.forEach((element) => {
            let point = {
                id: element.id,
                link: "",
                geocode: [element.latitude, element.longitude],
                popup: "",
            };
            markers.push(point);
        });
        return markers;
    };
    const handleMarkerClick = async (markerID) => {
        const request = { markerID: markerID };
        try {
            const result = await favorisService.getMarkerDetailsById(JSON.stringify(request));
            // Assuming the wheel data has a 'title' and 'price' property
            const popupValue = `${result.title}, ${result.price}`;
            // Assuming the wheel data has a 'url' property
            const linkValue = result.url;
            setCurrentPopup(popupValue);
            setCurrentLink(linkValue);
        } catch (error) {
            console.error("Error fetching wheel data:", error);
        }
    };
    return (
        <>
            {!Load ? (
                <MapContainer
                    bounds={[
                        [47.2701, 5.8663],
                        [55.0582, 15.0419],
                    ]}
                    center={[49.0416, 9.2512]}

                    maxBoundsViscosity={1.0}
                    maxZoom={18}
                    zoom={6}
                    locale="en"
                >
                    <TileLayer
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                        attribution="Tiles &copy; Esri"
                    />

                    {data && data.length > 0 && (
                        <MarkerClusterGroup
                            chunkedLoading
                            iconCreateFunction={createClusterCustomIcon}
                        >
                            {markers.map((marker, index) => (
                                <Marker
                                    key={index}
                                    position={marker.geocode}
                                    icon={myPositionIcon}
                                    eventHandlers={{
                                        click: () => handleMarkerClick(marker.id),
                                    }}
                                >
                                    <Popup>
                                        <a
                                            href={currentLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {currentPopup}
                                        </a>
                                    </Popup>
                                </Marker>
                            ))}
                        </MarkerClusterGroup>
                    )}

                </MapContainer>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <RotatingLines
                        width={30}
                        height={30}
                        strokeColor="#9cd047"
                        visible={true}
                    />
                </div>
            )}
        </>
    );
}
export default memo(FavorisCard);
