import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Box from "@mui/material/Box";
import { CSVLink } from "react-csv";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisitLink from "./icons/web.png";
import { RotatingLines } from "react-loader-spinner";
import {
    InputAdornment,
    TablePagination,
    TextField
} from "@material-ui/core";
import moment from "moment";
import { wheelsService } from "../../../_services/wheels.service";
import swal from "sweetalert";
import { favorisService } from "../../../_services/favoris.service";

function FavorisTable(props) {
    const date_of_download = new Date().toISOString().slice(0, 10);
    const [pageNumber, setPageNumber] = useState(1);
    const [sortedData, setSortedData] = useState([])
    const [dataLength, setDataLength] = useState(0);
    const [searchTitle, setSearchedTitle] = useState("");
    const [searchDescription, setSearchDescription] = useState("");
    const [sorting, setSorting] = React.useState({
        field: "length_title",
        order: "asc",
    });
    const [allData, setAllData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loaderFilter, setLoaderFilter] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = [

        {
            title: "",
            field: "tableData.id",
            render: (rowData) => (
                <input
                    type="checkbox"
                    checked={selectedRows.some(data => data.ID === rowData.ID)}
                    onChange={(e) => handleRowSelect(e, rowData)}
                />

            ),
        },
        {
            title:
                sorting.field === "ID" ? (
                    sorting.order === "desc" ? (
                        <span>
                            ID <ArrowUpwardIcon fontSize="small" />
                        </span>
                    ) : (
                        <span>
                            ID <ArrowDownwardIcon fontSize="small" />
                        </span>
                    )
                ) : (
                    "ID"
                ),
            field: "ID",
            width: 80,
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            field: "url",
            title: "Link",
            width: 80,
            sorting: "false",
            render: (value, tableMeta) => (
                <div style={{ display: "flex", alignItems: "center" }}>


                    <a onClick={() => handleRedirect(value.url)}>
                        <img
                            src={VisitLink}
                            alt=""
                            className="green-image"
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                objectFit: "cover",
                            }}
                        />
                    </a>
                </div>
            ),
        },
        {
            field: "image",
            title: "Bild",
            with: 80,

            render: (value) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    {value.image ? (
                        <img
                            src={value.image}
                            alt=""
                            style={{
                                margin: "5px",
                                width: "130px",
                                height: "90px",
                                borderRadius: "5px",
                                objectFit: "cover",
                            }}
                        />
                    ) : (
                        <span style={{ fontSize: "1rem" }}>K.A</span>
                    )}
                </div>
            ),
        },
        {
            title:
                sorting.field === "title" ? (
                    sorting.order === "desc" ? (
                        <span>
                            titel <ArrowUpwardIcon fontSize="small" />
                        </span>
                    ) : (
                        <span>
                            titel <ArrowDownwardIcon fontSize="small" />
                        </span>
                    )
                ) : (
                    "titel"
                ),
            field: "title",

            width: 150,
        },
        {
            title:
                sorting.field === "description" ? (
                    sorting.order === "desc" ? (
                        <span>
                            description <ArrowUpwardIcon fontSize="small" />
                        </span>
                    ) : (
                        <span>
                            description <ArrowDownwardIcon fontSize="small" />
                        </span>
                    )
                ) : (
                    "description"
                ),
            field: "description",

            width: 130,
        },
        {
            title:
                sorting.field === "adress" ? (
                    sorting.order === "desc" ? (
                        <span>
                            Adresse <ArrowUpwardIcon fontSize="small" />
                        </span>
                    ) : (
                        <span>
                            Adresse <ArrowDownwardIcon fontSize="small" />
                        </span>
                    )
                ) : (
                    "Adresse"
                ),
            width: 150,

            field: "adress",
        },
        {
            title:
                sorting.field === "date" ? (
                    sorting.order === "desc" ? (
                        <span>
                            Datum <ArrowUpwardIcon fontSize="small" />
                        </span>
                    ) : (
                        <span>
                            Datum <ArrowDownwardIcon fontSize="small" />
                        </span>
                    )
                ) : (
                    "Datum"
                ),
            field: "date",
            width: 100,

            render: (rowData) => (
                <span>{moment(rowData.date).format("DD.MM.YYYY")}</span>
            ),
        },
        {
            title:
                sorting.field === "price" ? (
                    sorting.order === "desc" ? (
                        <span>
                            preis <ArrowUpwardIcon fontSize="small" />
                        </span>
                    ) : (
                        <span>
                            preis <ArrowDownwardIcon fontSize="small" />
                        </span>
                    )
                ) : (
                    "preis"
                ),
            field: "price",
        },
        {
            title:
                sorting.field === "length_title" ? (
                    sorting.order === "desc" ? (
                        <span>
                            Count <ArrowUpwardIcon fontSize="small" />
                        </span>
                    ) : (
                        <span>
                            Count <ArrowDownwardIcon fontSize="small" />
                        </span>
                    )
                ) : (
                    "Count"
                ),
            field: "length_title",
        },
    ];

    // Function to handle row selection
    const handleRowSelect = (e, row) => {
        const newSelectedRows = [...selectedRows];
        const isChecked = e.target.checked;

        if (isChecked) {
            // If checkbox is checked, add the row to selectedRows
            newSelectedRows.push(row);
        } else {
            // If checkbox is unchecked, find the index of the row and remove it
            const selectedIndex = newSelectedRows.findIndex(selectedRow => selectedRow.ID === row.ID);
            if (selectedIndex !== -1) {
                newSelectedRows.splice(selectedIndex, 1);
            }
        }

        setSelectedRows(newSelectedRows);
    };

    function getDataFragment(numberPage, filteredData) {
        setPageNumber(numberPage);

    }
    const handleRedirect = (url) => {


        // Open a new tab 
        window.open(url, "_blank");
    };
    //get all data
    const getAllData = (favorisFolders) => {

        if (favorisFolders && favorisFolders.length > 0) {
            setLoaderFilter(true)
            let object = JSON.stringify({ folderNames: favorisFolders })
            favorisService.getAllFavoritesByFolders(object).then((result) => {
                setLoaderFilter(true)
                if (result.length > 0) {
                    setAllData(result);
                    setLoaderFilter(false)
                }
            });
        }
    };
    //set the sorting data 
    const handleSortingChange = (field, order) => {
        const fieldName = columns[field].field;
        const newOrder =
            sorting.field === fieldName && sorting.order === "asc" ? "desc" : "asc";
        setSorting({ field: fieldName, order: newOrder });
    };
    //function to fetch and sort data
    async function fetchData(activeButton, favorisFolders, sorting, e) {
        try {
            setLoader(true);
            if (activeButton === "favoris" && favorisFolders && favorisFolders.length > 0) {
                let object = JSON.stringify({ folderNames: favorisFolders })
                const result = await favorisService.getFavoritesByFolders(object)
                let filteredResult = result;
                if (searchTitle !== "" && searchDescription !== "") {
                    filteredResult = result.filter(item =>
                        item.title.toLowerCase().includes(searchTitle.toLowerCase()) ||
                        item.description.toLowerCase().includes(searchDescription.toLowerCase())
                    );
                }
                else if (searchTitle !== "") {
                    filteredResult = result.filter(item =>
                        item.title.toLowerCase().includes(searchTitle.toLowerCase())
                    );
                }
                else if (searchDescription !== "") {
                    filteredResult = result.filter(item =>
                        item.description.toLowerCase().includes(searchDescription.toLowerCase())
                    );
                }

                setDataLength(filteredResult?.length)
                const sortedItems = filteredResult?.slice().sort((a, b) => {
                    if (sorting.order === "asc") {
                        return a[sorting.field] > b[sorting.field] ? 1 : -1;
                    } else {
                        return a[sorting.field] < b[sorting.field] ? 1 : -1;
                    }
                });
                setSortedData(sortedItems)
                setLoader(false);

                getDataFragment(e, filteredResult);
            } else if (activeButton === "favoris" && favorisFolders.length === 0) {
                setSortedData([])
                setLoader(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoader(false);
        }
    }

    //pagination
    function handleChangePage(e) {
        setLoader(true)
        fetchData(props.activeButton, props.favorisFolders, sorting, e)

    }


    //filtred data with search text in autocomplete
    const handleChangeFilter = async (e, type) => {
        let searchString = e.target.value;
        let activeButton = props.activeButton;
        let favorisFolders = props.favorisFolders;
        let state = props.state
        if (type == "title") {
            setSearchedTitle(searchString);
            if (searchString == "" && searchDescription !== "") {

                setLoader(true);
                if (activeButton === "favoris" && favorisFolders && favorisFolders.length > 0) {
                    let object = JSON.stringify({ folderNames: favorisFolders })
                    const result = await favorisService.getFavoritesByFolders(object)
                    let filteredResult = result;

                    filteredResult = result.filter(item =>
                        item.description.toLowerCase().includes(searchDescription.toLowerCase())
                    );


                    setDataLength(filteredResult?.length)
                    const sortedItems = filteredResult?.slice().sort((a, b) => {
                        if (sorting.order === "asc") {
                            return a[sorting.field] > b[sorting.field] ? 1 : -1;
                        } else {
                            return a[sorting.field] < b[sorting.field] ? 1 : -1;
                        }
                    });
                    setSortedData(sortedItems)
                    setLoader(false);

                    getDataFragment(e, filteredResult);
                } else if (activeButton === "favoris" && favorisFolders.length === 0) {
                    setSortedData([])
                    setLoader(false);
                }
            }
            else if (searchString == "" && searchDescription == "") {
                setLoader(true);
                if (activeButton === "favoris" && favorisFolders && favorisFolders.length > 0) {
                    let object = JSON.stringify({ folderNames: favorisFolders })
                    const result = await favorisService.getFavoritesByFolders(object)
                    let filteredResult = result;
                    setDataLength(filteredResult?.length)
                    const sortedItems = filteredResult?.slice().sort((a, b) => {
                        if (sorting.order === "asc") {
                            return a[sorting.field] > b[sorting.field] ? 1 : -1;
                        } else {
                            return a[sorting.field] < b[sorting.field] ? 1 : -1;
                        }
                    });
                    setSortedData(sortedItems)
                    setLoader(false);

                    getDataFragment(e, filteredResult);
                } else if (activeButton === "favoris" && favorisFolders.length === 0) {
                    setSortedData([])
                    setLoader(false);
                }
            }

        }
        else if (type == "desc") {
            setSearchDescription(searchString);
            if (searchString == "" && searchTitle !== "") {

                setLoader(true);
                if (activeButton === "favoris" && favorisFolders && favorisFolders.length > 0) {
                    let object = JSON.stringify({ folderNames: favorisFolders })
                    const result = await favorisService.getFavoritesByFolders(object)
                    let filteredResult = result;

                    filteredResult = result.filter(item =>
                        item.title.toLowerCase().includes(searchTitle.toLowerCase())
                    );


                    setDataLength(filteredResult?.length)
                    const sortedItems = filteredResult?.slice().sort((a, b) => {
                        if (sorting.order === "asc") {
                            return a[sorting.field] > b[sorting.field] ? 1 : -1;
                        } else {
                            return a[sorting.field] < b[sorting.field] ? 1 : -1;
                        }
                    });
                    setSortedData(sortedItems)
                    setLoader(false);

                    getDataFragment(e, filteredResult);
                } else if (activeButton === "favoris" && favorisFolders.length === 0) {
                    setSortedData([])
                    setLoader(false);
                }
            }
            else if (searchString == "" && searchTitle == "") {
                setLoader(true);
                if (activeButton === "favoris" && favorisFolders && favorisFolders.length > 0) {
                    let object = JSON.stringify({ folderNames: favorisFolders })
                    const result = await favorisService.getFavoritesByFolders(object)
                    let filteredResult = result;
                    setDataLength(filteredResult?.length)
                    const sortedItems = filteredResult?.slice().sort((a, b) => {
                        if (sorting.order === "asc") {
                            return a[sorting.field] > b[sorting.field] ? 1 : -1;
                        } else {
                            return a[sorting.field] < b[sorting.field] ? 1 : -1;
                        }
                    });
                    setSortedData(sortedItems)
                    setLoader(false);

                    getDataFragment(e, filteredResult);
                } else if (activeButton === "favoris" && favorisFolders.length === 0) {
                    setSortedData([])
                    setLoader(false);
                }
            }

        }

    };
    useEffect(() => {

        fetchData(props.activeButton, props.favorisFolders, sorting, props.state)



    }, [sorting, props.favorisFolders, props.activeButton]);


    const handleDelete = (rowData) => {

        // Show confirmation dialog before removing from favorites
        swal({
            title: "Favoriten Löschen?",

            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let object = JSON.stringify({
                        selectedRows: rowData
                    })
                    favorisService.removeFromFavorites(object)
                        .then(res => {
                            swal("Erfolgreich gelöscht!", "", "success")
                            // Update the favorite_folder field to null for the removed item
                            setSelectedRows([])
                            const rowIds = new Set(rowData.map(row => row.ID));
                            const updatedSortedData = sortedData.filter(item => !rowIds.has(item.ID));
                            setSortedData(updatedSortedData);

                        })
                        .catch(error => {
                            swal("Error!", "Failed to delete from favorites.", "error");
                        });
                }
            });

    }


    return (
        <div className="container">
            <Box px={{ xs: "12px", lg: 0 }} className="page-space-top">
                <div
                    className="dataTable"
                >

                    <Box sx={{ height: "100%", width: "100%" }}>
                        {props.Loader || loader ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <RotatingLines
                                    width={60}
                                    height={60}
                                    strokeColor="#9cd047"
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <>


                                <div
                                    style={{ display: "flex", flexDirection: "column" }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: 'space-between'
                                        }}
                                    >

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "100%"
                                            }}
                                        >


                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                <div style={{
                                                    display: "flex"
                                                }} >
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: false,
                                                            style: {
                                                                textTransform: "uppercase",
                                                                padding: "0 5px 0 0",
                                                                backgroundColor: "white",
                                                            },
                                                        }}
                                                        id="searchTitle"
                                                        placeholder="Titel durchsuchen"
                                                        variant="outlined"
                                                        style={{
                                                            marginLeft: "0px",
                                                            marginRight: "0px",
                                                            marginBottom: "10px",
                                                            marginTop: "0.21rem",
                                                        }}
                                                        value={searchTitle}
                                                        onChange={(event) => {
                                                            handleChangeFilter(event, 'title');
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon sx={{ color: "#9cd047" }} />
                                                                </InputAdornment>
                                                            ),

                                                        }}
                                                    />
                                                    <button
                                                        className="btn btn-primary green-button"
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginBottom: "10px",
                                                            marginRight: "2px",
                                                            color: "#fff",
                                                            borderBottomLeftRadius: "0px",
                                                            borderTopLeftRadius: "0px",
                                                            backgroundColor: "#9cd047",
                                                            border: "1px solid #9cd047",
                                                            "&:hover": {
                                                                color: "#9cd047",
                                                                backgroundColor: "#fff",
                                                                border: "1px solid #9cd047",
                                                                variant: "outlined",
                                                            },
                                                        }}
                                                        onClick={() => fetchData(props.activeButton, props.favorisFolders, sorting, props.state)}
                                                    >
                                                        SUCHE
                                                    </button>
                                                </div>
                                                <div style={{
                                                    display: "flex"
                                                }} >
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: false,
                                                            style: {
                                                                textTransform: "uppercase",
                                                                padding: "0 5px 0 0",
                                                                backgroundColor: "white",
                                                            },
                                                        }}
                                                        id="searchDescription"
                                                        placeholder="Beschreibung durchsuchen"
                                                        variant="outlined"
                                                        style={{
                                                            marginLeft: "0px",
                                                            marginRight: "0px",
                                                            marginBottom: "10px",
                                                            marginTop: "0.21rem",
                                                        }}
                                                        value={searchDescription}
                                                        onChange={(event) => {
                                                            handleChangeFilter(event, 'desc');
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon sx={{ color: "#9cd047" }} />
                                                                </InputAdornment>
                                                            ),

                                                        }}
                                                    />
                                                    <button
                                                        className="btn btn-primary green-button"
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginBottom: "10px",
                                                            marginRight: "2px",
                                                            color: "#fff",
                                                            borderBottomLeftRadius: "0px",
                                                            borderTopLeftRadius: "0px",
                                                            backgroundColor: "#9cd047",
                                                            border: "1px solid #9cd047",
                                                            "&:hover": {
                                                                color: "#9cd047",
                                                                backgroundColor: "#fff",
                                                                border: "1px solid #9cd047",
                                                                variant: "outlined",
                                                            },
                                                        }}
                                                        onClick={() => fetchData(props.activeButton, props.favorisFolders, sorting, props.state)}
                                                    >
                                                        SUCHE
                                                    </button>
                                                    <div style={{
                                                        display: "flex"
                                                    }}>
                                                        {loaderFilter == null || loaderFilter == true ?
                                                            <button
                                                                className={loaderFilter ? "btn btn-primary disabled-button" : "btn button-color-black"}
                                                                style={{
                                                                    marginLeft: "auto",
                                                                    marginBottom: "10px",
                                                                    marginRight: "2px",

                                                                }}
                                                                onClick={() => getAllData(props.favorisFolders)}
                                                            >
                                                                {loaderFilter ? <a>CSV wir geladen...</a>
                                                                    :
                                                                    <span>CSV Download</span>
                                                                }
                                                            </button>
                                                            : loaderFilter == false ?

                                                                <CSVLink
                                                                    className="btn btn-primary"
                                                                    style={{
                                                                        marginLeft: "auto",
                                                                        marginBottom: "10px",
                                                                        marginRight: "2px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        color: "#fff",
                                                                        backgroundColor: "#9cd047",
                                                                        border: "1px solid #9cd047",
                                                                        "&:hover": {
                                                                            color: "#9cd047",
                                                                            backgroundColor: "#fff",
                                                                            border: "1px solid #9cd047",
                                                                            variant: "outlined",
                                                                        },
                                                                    }}
                                                                    separator=";"
                                                                    data={allData}
                                                                    filename={`bekstein_annoncen_${date_of_download}.csv`}
                                                                >
                                                                    Jetzt herunterladen
                                                                </CSVLink>
                                                                : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="mb-3">
                                        {selectedRows && selectedRows.length > 0 &&
                                            <button onClick={() => handleDelete(selectedRows)}>Delete</button>
                                        }
                                    </div>

                                    <div
                                        style={{ height: "100%", width: "100%", overflow: "auto" }}
                                        className="ag-fresh customer-table"
                                    >
                                        <MaterialTable
                                            localization={{
                                                body: {
                                                    emptyDataSourceMessage:
                                                        sortedData.length == 0 &&
                                                        <div style={{ padding: "20px 0px" }}>
                                                            Leider wurden keine übereinstimmenden Datensätze gefunden.

                                                        </div>
                                                },

                                                pagination: {
                                                    labelDisplayedRows: "{from} bis {to} von {count} ",
                                                    labelRowsSelect: "Zeilen",
                                                    previousAriaLabel: "Vorherige Seite",
                                                    previousTooltip: "Vorherige Seite",
                                                    nextAriaLabel: "Nächste Seite",
                                                    nextTooltip: "Nächste Seite",
                                                    lastAriaLabel: "Letzte Seite",
                                                    lastTooltip: "Letzte Seite",
                                                },
                                            }}
                                            title={""}
                                            data={sortedData}
                                            columns={columns}
                                            options={{
                                                toolbar: false,
                                                loading: true,
                                                sorting: true,
                                                pageSize: 200,
                                                pageSizeOptions: false,
                                                actionsColumnIndex: -1,
                                                search: false,
                                            }}
                                            components={{
                                                Pagination: (props) => (
                                                    <TablePagination
                                                        {...props}
                                                        /* rowsPerPageOptions={[5]} */
                                                        rowsPerPage={200}
                                                        count={dataLength}
                                                        page={pageNumber - 1}
                                                        onChangePage={(e, page) => {
                                                            handleChangePage(page + 1);
                                                        }}
                                                    />
                                                ),
                                            }}
                                            onOrderChange={(orderedColumnId, orderDirection) => {
                                                handleSortingChange(orderedColumnId, orderDirection);
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </Box>
                </div>
            </Box>

        </div>
    );
}
export default (FavorisTable);
